import React from 'react';
import Figure from 'react-bootstrap/Figure';
import Image from 'react-bootstrap/Image';
import terminal from '../../static/images/close-up-customer-paying-by-credit-card.jpg';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import { pad } from '../komponents/NotFound/404.module.scss';

function fourzerofour() {
  return (
    <>
      <Header />
      <section className="py-28 bg-white overflow-hidden">
        <div className="container" style="margin-top: 5%">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 mb-12 mb-md-0">
              <div className="mw-sm mx-auto ms-md-0">
                <h3 className="mb-12 text-primary display-5 lh-sm font-heading">
                  Transacción Declinada!
                </h3>
                <h4 className="mb-10 display-5 lh-sm font-heading">
                  No encontramos esa página. Puedes ir a la pagina de inicio
                  para comparar servicios de pago.
                </h4>
                <a className="btn btn-dark d-block d-md-inline-block" href="/">
                  Inicio de PuntoTerminal
                </a>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <Figure className="mb-0 position-relative">
                <Image
                  className="img-fluid position-relative"
                  src={terminal}
                  alt="Cliente pagando con tarjeta credito"
                  title="Cliente pagando con tarjeta credito"
                  style="z-index: 2;"
                />
                <div
                  className="position-absolute top-0 start-0 bg-primary-light w-100 h-100 mt-6 ms-6 mt-lg-12 ms-lg-12"
                  style="z-index: 1;"
                />
              </Figure>
            </div>
          </div>
        </div>
      </section>
      <div className={pad} />
      <Footer />
    </>
  );
}

export default fourzerofour;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="404 page" />
    <title>Pagina no encontrada - PuntoTerminal.mx</title>
  </>
);
